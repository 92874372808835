<template>
    <section>
        <div class="row mx-0 align-items-center">
            <div class="col-auto f-17 f-600 text-general">
                Paginas de inicio
            </div>
            <div class="col-3 ml-auto">
                <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar" size="small" class="w-100 br-20" @input="buscarPage" />
            </div>
            <div v-if="$can('boton_crear_landing')" class="btn-general w-137px h-36px px-3 mx-2" @click="crearPagina">
                Crear página
            </div>
            <div class="btn-general w-137px h-36px px-3 mx-2" @click="$router.push({name: 'admin.paginas.inicio.footer'})">
                Footer
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3 pb-3" style="height:calc(100vh - 120px)">
            <div v-if="landingsLista.length == 0 || buscar.length >0 && landingsBusqueda.length == 0" class="vh-100 row justify-content-center align-items-center">
                <div class=" py-3 px-4 mr-3 mb-3 text center">
                    Aún no se han creado páginas de inicio
                    <div class="btn-general px-3 mx-2" @click="crearPagina">
                        Crear página
                    </div>
                </div>
            </div>
            <div v-if="landingsBusqueda.length > 0" class="row mx-0">
                <div v-for="data in landingsBusqueda" id="cardPagina" :key="data.id" class="card-pagina cr-pointer py-3 px-4 mr-3 mb-3" @click="$router.push({name: 'admin.paginas-inicio.editar', params: {id_landing: data.id }});">
                    <!-- <preview-pagina /> -->
                    <div class="row mx-0 mt-3">
                        <p class="col-12 px-0 f-18 text-general f-600 mb-2">
                            {{ data.nombre }}
                        </p>
                        <p v-if="data.estado == 1" class="col-auto text-general px-0 f-14">
                            Publicado
                        </p>
                        <p v-else class="col-auto text-general px-0 f-14">
                            No publicado
                        </p>
                        <div class="col-auto d-middle ml-auto text-general">
                            <span class="f-600"> {{ data.cantidad_cedis }} </span>
                            <i class="icon-cedis f-15" />
                        </div>
                    </div>
                    <p class="col-auto text-general px-0 f-14 m-auto">
                        Visitas totales <b> {{ separadorNumeroDecimales(data.cantidad_visitas, decimales = 0, simbolo=false) }} </b>
                    </p>
                </div>
            </div>
            <div v-else class="row mx-0">
                <div v-for="(data, key) in landingsLista" id="cardPagina" :key="key" class="card-pagina cr-pointer py-3 px-4 mr-3 mb-3" @click="$router.push({name: 'admin.paginas-inicio.editar', params: {idLanding: data.id }});">
                    <!-- <preview-pagina /> -->
                    <div class="row mx-0 mt-3">
                        <p class="col-12 px-0 f-18 text-general f-600 mb-2">
                            {{ data.nombre }}
                        </p>
                        <p v-if="data.estado == 1" class="col-auto text-general px-0 f-14">
                            Publicado
                        </p>
                        <p v-else class="col-auto text-general px-0 f-14">
                            No publicado
                        </p>
                        <div class="col-auto d-middle ml-auto text-general">
                            <span class="f-600"> {{ data.cantidad_cedis }} </span>
                            <i class="icon-cedis f-15" />
                        </div>
                    </div>
                    <p class="col-auto text-general px-0 f-14 mb-2">
                        Visitas totales <b> {{ separadorNumeroDecimales(data.cantidad_visitas, decimales = 0, simbolo=false) }} </b>
                    </p>
                </div>
            </div>
            <div class="row mx-0">
                <div id="mostrar" />
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalCrearPagina" titulo="Crear página" no-aceptar adicional="Guardar" @adicional="crearLanding">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <p class="pl-3 f-12 text-general">Nombre la página</p>
                    <el-input v-model="nombre" class="w-100" size="small" />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Landing from '~/services/landing/landing_admin'
export default {
    data(){
        return {
            nombre: '',
            buscar: '',
            urlPage:'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
            landingsLista: [],
            landingsBusqueda: [],
        }
    },
    mounted(){
        this.getListaLandings();
    },
    methods: {
        crearPagina(){
            this.nombre = ''
            this.$refs.modalCrearPagina.toggle();
        },
        async getListaLandings(){
            try {
                const { data } = await Landing.getListaLandings()
                this.landingsLista = data.landingsLista
            } catch (error){
                return this.error_catch(error)
            }
        },
        async crearLanding(){
            try {
                const payload = {
                    nombreLanding : this.nombre
                }
                const { data } = await Landing.crearLanding(payload)
                this.getListaLandings()
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$refs.modalCrearPagina.toggle();
            } catch (error){
                return this.error_catch(error)
            }
        },
        buscarPage(){
            this.landingsLista.forEach((item, index) => {
                let nombre = item.nombre.toLowerCase()
                if(nombre.indexOf(this.buscar.toLowerCase()) != -1){
                    if(this.landingsBusqueda.findIndex(i => i.id == item.id) == -1){
                        this.landingsBusqueda.push(this.landingsLista[index])
                    }
                }else{
                    this.landingsBusqueda.splice(index, 1)
                }
                if(this.buscar.length == 0){
                    this.landingsBusqueda = []
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.card-pagina{
    width: 369px;
    height: 148px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.w-137px{
	width: 137px;
}
.h-36px{
	height: 36px;
}
</style>
